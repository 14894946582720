@import "_vars";

@import "_sidenav";

article {
  h1 {
    border-bottom: 1px solid $color_border_gray;
    padding: 0 0 15px 0;
    @include max-screen($sp_size) {
      border: none;
      padding: 0;
      color: $color_text;
      font-size: 18px;
    }
  }

  .sort {
    @include flexbox;
    align-items: center;
    margin-bottom: 20px;
    font-size: 13px;
    @include max-screen($sp_size) {
      margin-bottom: 10px;
    }

    dt {
      @include max-screen($sp_size) {
        margin-right: 5px;
      }
    }

    dd {
      padding: 2px 0;
      &:not(:last-of-type) {
        margin-right: 10px;
        @include max-screen($sp_size) {
          margin-right: 0;
        }
      }

      button {
        border: 1px solid $color_sort;
        border-radius: 4px;
        padding: 4px 10px;
        @include max-screen($sp_size) {
          border-color: $color_border_gray;
          border-radius: 0;
          span {
            display: none;
          }
        }
      }

      @include max-screen($sp_size) {
        &:not(:first-of-type) {
          button {
            border-left: none;
          }
        }
      }

      &.selected {
        button {
          background-color: $color_sort;
          color: #fff;          
          font-weight: bold;
          @include max-screen($sp_size) {
            background-color: $color_bg_gray;
            color: $color_text;
            font-weight: normal;
          }
        }
      }
    }
  }

  .result {
    @include flexbox;
    li {
      width: 24%;
      margin-bottom: 25px;
      @include max-screen($sp_size) {
        width: 48%;
        margin-bottom: 20px;
      }

      &:not(:nth-child(4n)) {
        @include min-screen($pc_size) {
          margin-right: calc(4% / 3);
        }
      }

      &:nth-child(even) {
        @include max-screen($sp_size) {
          margin-left: 3vw;
        }
      }

      .tag {
        @include flexbox;
        align-items: flex-end;
        height: 2.8vw;
        max-height: 29px;
        margin-bottom: 5px;
        @include max-screen($sp_size) {
          height: 5.5vw;
          min-height: 18px;
          max-height: none;
        }

        span {
          @include flexbox;
          align-items: center;
          justify-content: center;
          max-width: calc((100% - 14px) / 3);
          @include max-screen($sp_size) {
            max-width: calc((100% - 8px) / 3);
          }

          &:not(:last-child) {
            margin-right: 7px;
            @include max-screen($sp_size) {
              margin-right: 4px;
            }
          }
        }
      }

      .thumbnail {
        position: relative;
        margin-bottom: 5px;
        display: block;

        .discount {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: $color_attention;
          border-radius: 4px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          padding: 5px 10px;
          @include max-screen($sp_size) {
            top: 5px;
            right: 5px;
            font-size: 10px;
            padding: 1px 5px;
          }
        }

        .thmbnail_image {
          img {
            width: 100%;            
          }
        }

        .qview {
          position: absolute;
          bottom: 5px;
          left: 5px;
          background-color: #fff;
          border: 1px solid $color_main_dark ;
          border-radius: 2px;
          font-size: 12px;
          color: $color_main_text;
          box-shadow: 0 2px 3px rgba(0,0,0,0.2);
          padding: 3px 13px;
          text-decoration: none;
          @include max-screen($sp_size) {
            display: none;
          }
        }
      }

      .swatch {
        @include flexbox;
        margin-bottom: 5px;

        a,
        span {
          display: block;
          cursor: pointer;
          width: 18px;
          height: 18px;
          padding: 3px 0 0 3px;
          @include max-screen($sp_size) {
            width: 16px;
            height: 16px;
          }
        }

        a {
          img {
            width: 14px;
            height: 14px;
            @include max-screen($sp_size) {
              width: 13px;
              height: 13px;
            }
          }

          &:nth-of-type(n + 7) {
            display: none;
          }
        }
      }

      .item_thumbnail_name {
        margin-bottom: 3px;
        @include max-screen($sp_size) {
          font-size: 12px;
          text-decoration: underline;
        }
      }

      .item_thumbnail_price {
        margin-bottom: 3px;
        @include max-screen($sp_size) {
          font-size: 12px;
        }

        .cross_out {
          @include max-screen($sp_size) {
            font-weight: bold;
          }
        }

        .special_price {
          font-size: 12px;
          font-weight: normal;
          margin-top: 3px;
          span {
            @include max-screen($sp_size) {
              font-weight: normal;
            }
          }
        }
      }

      .item_thumbnail_rating {
        margin-bottom: 0;
      }
    }
  }

  // No Result
  .no_result_message {
    font-size: 18px;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 20px;
    margin-bottom: 25px;
    @include max-screen($sp_size) {
      font-size: 14px;
      padding: 0;
      margin-bottom: 15px;
    }
  }
  .no_result_help {
    background-color: $color_bg_gray;
    padding: 25px;
    margin-bottom: 25px;
    @include max-screen($sp_size) {
      padding: 15px;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      padding-left: 1em;
      text-indent: -1em;
      @include max-screen($sp_size) {
        font-size: 12px;
      }

      &:not(:first-child) {
        margin-top: 5px;
        @include max-screen($sp_size) {
          margin-top: 2px;
        }
      }
    }
  }
  .no_result_cs {
    font-size: 16px;
    padding-left: 20px;
    margin-bottom: 25px;
    @include max-screen($sp_size) {
      font-size: 14px;
      padding-left: 0;
      margin-bottom: 15px;
    }

    span {
      display: block;
      color: $color_main;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .no_result_search {
    border: 1px dotted $color_border_gray;
    padding: 25px;
    @include max-screen($sp_size) {
      padding: 15px;
    }
    p {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      @include max-screen($sp_size) {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .keyword_search {
      width: 100%;
      @include max-screen($sp_size) {
        text-align: center;
        form {
          .icon_search {
            transform: none;
            top: 8px;
          }
          .btn_search {
            position: static;
            transform: none;
            margin: 10px auto 0;
            width: 130px;
            height: 35px;
          }
        }
      }
    }
  }

  // Feature
  &.feature {
    .feature_inner {
      width: 100%;
      @include max-screen($sp_size) {
        padding: 0 3%;
      }
    }

    .main_image {
      margin-bottom: 40px;
      @include max-screen($sp_size) {
        margin-bottom: 20px;
      }
    }

    h2 {
      margin-bottom: 40px;
      @include max-screen($sp_size) {
        margin-bottom: 15px;
        color: $color_main_dark;
        span {
          display: none;
        }
      }
    }

    .view_more {
      padding-bottom: 20px;
      border-bottom: 1px solid $color_border_gray;
      margin-bottom: 40px;
      @include max-screen($sp_size) {
        padding-bottom: 10px;
        margin-bottom: 20px;
        text-align: right;
        a {
          display: inline-block;
          text-decoration: underline;
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }
    }

    &:not(.feature2) {
      .result {
        li {
          font-size: 14px;

          .item_thumbnail_price {
            .special_price {
              font-size: 14px;
              @include max-screen($sp_size) {
                font-size: 12px;
              }
            }
          }
        }
      }      
    }

    &.feature2 {
      .result {
        // li {
        //   width: 19%;

        //   &:not(:nth-child(4n)) {
        //     @include min-screen($pc_size) {
        //       margin-right: calc(4% / 5);
        //     }
        //   }
        // }

        li {
          @include min-screen($pc_size) {
            width: 242px;
            margin: 0 5px 30px;

            &:not(:nth-child(4n)) {
              margin: 0 5px 30px;
            }            
          }
        }
      }
    }
  }

}// End article


/* Filter for SP */
.sp_filter {
  @include min-screen($pc_size) {
    display: none;
  }
  @include max-screen($sp_size) {
    @include flexbox;
    align-items: center;
    font-size: 13px;
    margin-bottom: 20px;
    p {
      margin-right: 5px;
    }
    button {
      color: $color_main_dark;
      border: 1px solid $color_main_dark;
      padding: 4px 5px;
    }
  }
}


.bg_filter {
  @include min-screen($pc_size) {
    display: none !important;
  }
  @include max-screen($sp_size) {
    display: none;
    &.active {
      display: block;
      background-color: rgba(255, 255, 255, 0.8);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .filter_header {
      @include flexbox;
      align-items: center;
      justify-content: space-between;
      padding: 5px 5%;
      border-bottom: 1px solid #000;
      background-color: #e9e9e9;
      p {
        margin: 0 10px;
        font-size: 16px;
        color: #36681f;
        font-weight: bold;
      }
      button {
        background-color: #fff;
        border: 1px solid #6c905b;
        // width: 53px;
        // height: 24px;
        color: #36681f;
        padding: 5px;
      }
    }
    .filter_footer {
      padding: 5px 5%;
      text-align: center;
      margin-top: 30px;
      button {
        background-color: #fff;
        border: 1px solid #6c905b;
        width: 100%;
        height: 40px;
        font-size: 16px;
        color: #36681f;
      }
    }

    .filter_result {
      width: 90%;
      margin: auto;
      padding: 10px 0;
    }

    .filter_wrap {
      width: 90%;
      height: 65%;
      overflow: hidden;
      margin: auto;

      .filter {
        border-top: 1px solid #efe9da;
        border-left: 1px solid #efe9da;
        border-right: 1px solid #efe9da;

        .filter_name {
          background-color: #efeff4;
          padding: 4px 2px 4px 10px;
          font-weight: bold;
          cursor: pointer;
          position: relative;
          @include flexbox;
          align-items: center;
          justify-content: space-between;

          a {
            margin-right: 30px;
            display: inline-block;
            font-weight: normal;
            font-size: 10px;
          }

          .filter_switch {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url(../img/common/icon_open.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @include centering_vertically;
            right: 2px;
            &.active {
              background-image: url(../img/common/icon_close.png);
            }
          }
        }

        .filter_option {
          display: none;

          li {
            border-top: 1px solid #efe9da;
            padding: 5px 0;
            padding-left: 10px;
            background-color: #fff;

            input[type=checkbox] {
              display: none;

              & + label {
                padding-left: 25px;
                height: 20px;
                &:before {
                  width: 20px;
                  height: 20px;
                }

                span {
                  vertical-align: middle;
                }
              }

              &:checked{
                & + label {
                  &:after {
                    width: 11px;
                    height: 7px;
                    left: 5px;
                    margin-top: -7px;
                  }
                }
              }
            }
          }

          &.filter_option_color {
            font-size: 0;
            
            li {
              display: inline-block;
              font-size: 12px;
              padding: 0;
              margin: 4px 2px;

              label {
                width: 30px;
                height: 30px;
                padding: 2px;
                font-size: 12px;
                border: 2px solid #999;

                &.selected {
                  border: 2px solid $color_frame-selected;
                }

                &.unavailable {
                  border: 2px dotted #999;
                  pointer-events: none;
                }

                .filter_color {
                  width: 22px;
                  height: 22px;
                  display: block;

                  &#filter_color01 {
                    background-color: red;
                  }
                  &#filter_color02 {
                    background-color: blue;
                  }
                  &#filter_color03 {
                    background-color: yellow;
                  }
                  &#filter_color04 {
                    background-color: green;
                  }
                  &#filter_color05 {
                    background-color: black;
                  }
                }              
              }
            }
          }
        }
      }
    }
  }
}