@media screen and (max-width: 767px) {
  aside {
    display: none;
  }
}

aside .condition {
  margin-bottom: 10px;
}

aside .condition a {
  display: block;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  aside .condition a:hover {
    text-decoration: underline;
  }
}

aside .condition .condition_result {
  font-size: 14px;
  font-weight: bold;
}

aside .condition .condition_result .condition_result_h {
  color: #435e54;
  padding-bottom: 8px;
}

aside .condition .condition_result .condition_result_string {
  padding-bottom: 5px;
  border-bottom: 1px solid #d0d0d0;
}

aside .condition .condition_category {
  font-size: 13px;
  border-bottom: 1px solid #d0d0d0;
}

aside .condition .condition_category .category_upper a {
  font-weight: bold;
  padding: 6px 0;
  padding-left: 20px;
}

aside .condition .condition_category .category_upper.selected a {
  background-color: #efefef;
}

aside .condition .condition_category .category_lower a {
  font-weight: bold;
  padding: 5px 0;
  padding-left: 30px;
}

aside .condition .condition_category .option li a {
  padding: 5px 0;
  padding-left: 40px;
}

aside .condition .condition_category .option li.selected a {
  background-color: #efefef;
}

aside .filter_h {
  font-size: 13px;
  font-weight: bold;
  color: #435e54;
  margin-bottom: 5px;
}

aside .filter_wrap {
  font-size: 13px;
}

aside .filter_wrap .filter:not(:first-child) {
  margin-top: 10px;
}

aside .filter_wrap .filter .filter_name {
  background-color: #efefef;
  padding: 4px 2px 4px 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

aside .filter_wrap .filter .filter_name a {
  margin-right: 30px;
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}

aside .filter_wrap .filter .filter_name .filter_switch {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(../img/common/icon_open.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
}

aside .filter_wrap .filter .filter_name .filter_switch.active {
  background-image: url(../img/common/icon_close.png);
}

aside .filter_wrap .filter .filter_option {
  display: none;
}

aside .filter_wrap .filter .filter_option li {
  margin-top: 10px;
  padding-left: 15px;
}

aside .filter_wrap .filter .filter_option li input[type=checkbox] {
  display: none;
}

aside .filter_wrap .filter .filter_option li input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  height: 20px;
}

aside .filter_wrap .filter .filter_option li input[type=checkbox] + label:before {
  width: 20px;
  height: 20px;
  margin-top: -11px;
}

aside .filter_wrap .filter .filter_option li input[type=checkbox]:checked + label:after {
  width: 12px;
  height: 8px;
  left: 4px;
  margin-top: -7px;
  border-width: 0 0 2px 2px;
}

aside .filter_wrap .filter .filter_option.filter_option_color {
  font-size: 0;
  padding-top: 5px;
}

aside .filter_wrap .filter .filter_option.filter_option_color li {
  display: inline-block;
  font-size: 12px;
  padding: 0;
  margin: 5px 2px 0;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label {
  width: 30px;
  height: 30px;
  padding: 2px;
  font-size: 12px;
  border: 2px solid #999;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label.selected {
  border: 2px solid #d77500;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label.unavailable {
  border: 2px dotted #999;
  pointer-events: none;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label .filter_color {
  width: 22px;
  height: 22px;
  display: block;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color01 {
  background-color: red;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color02 {
  background-color: blue;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color03 {
  background-color: yellow;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color04 {
  background-color: green;
}

aside .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color05 {
  background-color: black;
}

article h1 {
  border-bottom: 1px solid #d0d0d0;
  padding: 0 0 15px 0;
}

@media screen and (max-width: 767px) {
  article h1 {
    border: none;
    padding: 0;
    color: #464749;
    font-size: 18px;
  }
}

article .sort {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  font-size: 13px;
}

@media screen and (max-width: 767px) {
  article .sort {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  article .sort dt {
    margin-right: 5px;
  }
}

article .sort dd {
  padding: 2px 0;
}

article .sort dd:not(:last-of-type) {
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  article .sort dd:not(:last-of-type) {
    margin-right: 0;
  }
}

article .sort dd button {
  border: 1px solid #869e2c;
  border-radius: 4px;
  padding: 4px 10px;
}

@media screen and (max-width: 767px) {
  article .sort dd button {
    border-color: #d0d0d0;
    border-radius: 0;
  }
  article .sort dd button span {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  article .sort dd:not(:first-of-type) button {
    border-left: none;
  }
}

article .sort dd.selected button {
  background-color: #869e2c;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  article .sort dd.selected button {
    background-color: #efefef;
    color: #464749;
    font-weight: normal;
  }
}

article .result {
  display: flex;
  flex-wrap: wrap;
}

article .result li {
  width: 24%;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  article .result li {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  article .result li:not(:nth-child(4n)) {
    margin-right: calc(4% / 3);
  }
}

@media screen and (max-width: 767px) {
  article .result li:nth-child(even) {
    margin-left: 3vw;
  }
}

article .result li .tag {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 2.8vw;
  max-height: 29px;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  article .result li .tag {
    height: 5.5vw;
    min-height: 18px;
    max-height: none;
  }
}

article .result li .tag span {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: calc((100% - 14px) / 3);
}

@media screen and (max-width: 767px) {
  article .result li .tag span {
    max-width: calc((100% - 8px) / 3);
  }
}

article .result li .tag span:not(:last-child) {
  margin-right: 7px;
}

@media screen and (max-width: 767px) {
  article .result li .tag span:not(:last-child) {
    margin-right: 4px;
  }
}

article .result li .thumbnail {
  position: relative;
  margin-bottom: 5px;
  display: block;
}

article .result li .thumbnail .discount {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #a8192e;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
}

@media screen and (max-width: 767px) {
  article .result li .thumbnail .discount {
    top: 5px;
    right: 5px;
    font-size: 10px;
    padding: 1px 5px;
  }
}

article .result li .thumbnail .thmbnail_image img {
  width: 100%;
}

article .result li .thumbnail .qview {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #fff;
  border: 1px solid #3b5343;
  border-radius: 2px;
  font-size: 12px;
  color: #435e54;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  padding: 3px 13px;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  article .result li .thumbnail .qview {
    display: none;
  }
}

article .result li .swatch {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

article .result li .swatch a,
article .result li .swatch span {
  display: block;
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 3px 0 0 3px;
}

@media screen and (max-width: 767px) {
  article .result li .swatch a,
  article .result li .swatch span {
    width: 16px;
    height: 16px;
  }
}

article .result li .swatch a img {
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 767px) {
  article .result li .swatch a img {
    width: 13px;
    height: 13px;
  }
}

article .result li .swatch a:nth-of-type(n + 7) {
  display: none;
}

article .result li .item_thumbnail_name {
  margin-bottom: 3px;
}

@media screen and (max-width: 767px) {
  article .result li .item_thumbnail_name {
    font-size: 12px;
    text-decoration: underline;
  }
}

article .result li .item_thumbnail_price {
  margin-bottom: 3px;
}

@media screen and (max-width: 767px) {
  article .result li .item_thumbnail_price {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  article .result li .item_thumbnail_price .cross_out {
    font-weight: bold;
  }
}

article .result li .item_thumbnail_price .special_price {
  font-size: 12px;
  font-weight: normal;
  margin-top: 3px;
}

@media screen and (max-width: 767px) {
  article .result li .item_thumbnail_price .special_price span {
    font-weight: normal;
  }
}

article .result li .item_thumbnail_rating {
  margin-bottom: 0;
}

article .no_result_message {
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  article .no_result_message {
    font-size: 14px;
    padding: 0;
    margin-bottom: 15px;
  }
}

article .no_result_help {
  background-color: #efefef;
  padding: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  article .no_result_help {
    padding: 15px;
    margin-bottom: 15px;
  }
}

article .no_result_help p {
  font-size: 16px;
  padding-left: 1em;
  text-indent: -1em;
}

@media screen and (max-width: 767px) {
  article .no_result_help p {
    font-size: 12px;
  }
}

article .no_result_help p:not(:first-child) {
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  article .no_result_help p:not(:first-child) {
    margin-top: 2px;
  }
}

article .no_result_cs {
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  article .no_result_cs {
    font-size: 14px;
    padding-left: 0;
    margin-bottom: 15px;
  }
}

article .no_result_cs span {
  display: block;
  color: #0c5641;
  font-weight: bold;
  margin-bottom: 10px;
}

article .no_result_search {
  border: 1px dotted #d0d0d0;
  padding: 25px;
}

@media screen and (max-width: 767px) {
  article .no_result_search {
    padding: 15px;
  }
}

article .no_result_search p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  article .no_result_search p {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

article .no_result_search .keyword_search {
  width: 100%;
}

@media screen and (max-width: 767px) {
  article .no_result_search .keyword_search {
    text-align: center;
  }
  article .no_result_search .keyword_search form .icon_search {
    transform: none;
    top: 8px;
  }
  article .no_result_search .keyword_search form .btn_search {
    position: static;
    transform: none;
    margin: 10px auto 0;
    width: 130px;
    height: 35px;
  }
}

article.feature .feature_inner {
  width: 100%;
}

@media screen and (max-width: 767px) {
  article.feature .feature_inner {
    padding: 0 3%;
  }
}

article.feature .main_image {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  article.feature .main_image {
    margin-bottom: 20px;
  }
}

article.feature h2 {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  article.feature h2 {
    margin-bottom: 15px;
    color: #3b5343;
  }
  article.feature h2 span {
    display: none;
  }
}

article.feature .view_more {
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  article.feature .view_more {
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: right;
  }
  article.feature .view_more a {
    display: inline-block;
    text-decoration: underline;
    border: none;
    box-shadow: none;
    padding: 0;
  }
}

article.feature:not(.feature2) .result li {
  font-size: 14px;
}

article.feature:not(.feature2) .result li .item_thumbnail_price .special_price {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  article.feature:not(.feature2) .result li .item_thumbnail_price .special_price {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  article.feature.feature2 .result li {
    width: 242px;
    margin: 0 5px 30px;
  }
  article.feature.feature2 .result li:not(:nth-child(4n)) {
    margin: 0 5px 30px;
  }
}

/* Filter for SP */
@media screen and (min-width: 768px) {
  .sp_filter {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp_filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
    margin-bottom: 20px;
  }
  .sp_filter p {
    margin-right: 5px;
  }
  .sp_filter button {
    color: #3b5343;
    border: 1px solid #3b5343;
    padding: 4px 5px;
  }
}

@media screen and (min-width: 768px) {
  .bg_filter {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .bg_filter {
    display: none;
  }
  .bg_filter.active {
    display: block;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bg_filter .filter_header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5%;
    border-bottom: 1px solid #000;
    background-color: #e9e9e9;
  }
  .bg_filter .filter_header p {
    margin: 0 10px;
    font-size: 16px;
    color: #36681f;
    font-weight: bold;
  }
  .bg_filter .filter_header button {
    background-color: #fff;
    border: 1px solid #6c905b;
    color: #36681f;
    padding: 5px;
  }
  .bg_filter .filter_footer {
    padding: 5px 5%;
    text-align: center;
    margin-top: 30px;
  }
  .bg_filter .filter_footer button {
    background-color: #fff;
    border: 1px solid #6c905b;
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: #36681f;
  }
  .bg_filter .filter_result {
    width: 90%;
    margin: auto;
    padding: 10px 0;
  }
  .bg_filter .filter_wrap {
    width: 90%;
    height: 65%;
    overflow: hidden;
    margin: auto;
  }
  .bg_filter .filter_wrap .filter {
    border-top: 1px solid #efe9da;
    border-left: 1px solid #efe9da;
    border-right: 1px solid #efe9da;
  }
  .bg_filter .filter_wrap .filter .filter_name {
    background-color: #efeff4;
    padding: 4px 2px 4px 10px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .bg_filter .filter_wrap .filter .filter_name a {
    margin-right: 30px;
    display: inline-block;
    font-weight: normal;
    font-size: 10px;
  }
  .bg_filter .filter_wrap .filter .filter_name .filter_switch {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../img/common/icon_open.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2px;
  }
  .bg_filter .filter_wrap .filter .filter_name .filter_switch.active {
    background-image: url(../img/common/icon_close.png);
  }
  .bg_filter .filter_wrap .filter .filter_option {
    display: none;
  }
  .bg_filter .filter_wrap .filter .filter_option li {
    border-top: 1px solid #efe9da;
    padding: 5px 0;
    padding-left: 10px;
    background-color: #fff;
  }
  .bg_filter .filter_wrap .filter .filter_option li input[type=checkbox] {
    display: none;
  }
  .bg_filter .filter_wrap .filter .filter_option li input[type=checkbox] + label {
    padding-left: 25px;
    height: 20px;
  }
  .bg_filter .filter_wrap .filter .filter_option li input[type=checkbox] + label:before {
    width: 20px;
    height: 20px;
  }
  .bg_filter .filter_wrap .filter .filter_option li input[type=checkbox] + label span {
    vertical-align: middle;
  }
  .bg_filter .filter_wrap .filter .filter_option li input[type=checkbox]:checked + label:after {
    width: 11px;
    height: 7px;
    left: 5px;
    margin-top: -7px;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color {
    font-size: 0;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li {
    display: inline-block;
    font-size: 12px;
    padding: 0;
    margin: 4px 2px;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label {
    width: 30px;
    height: 30px;
    padding: 2px;
    font-size: 12px;
    border: 2px solid #999;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label.selected {
    border: 2px solid #d77500;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label.unavailable {
    border: 2px dotted #999;
    pointer-events: none;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label .filter_color {
    width: 22px;
    height: 22px;
    display: block;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color01 {
    background-color: red;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color02 {
    background-color: blue;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color03 {
    background-color: yellow;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color04 {
    background-color: green;
  }
  .bg_filter .filter_wrap .filter .filter_option.filter_option_color li label .filter_color#filter_color05 {
    background-color: black;
  }
}
