@import "_vars";

aside {
  @include max-screen($sp_size) {
    display: none;
  }

  .condition {
    margin-bottom: 10px;

    a {
      display: block;
      text-decoration: none;
      @include min-screen($pc_size) {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .condition_result {
      font-size: 14px;
      font-weight: bold;
      .condition_result_h {
        color: $color_main_text;
        padding-bottom: 8px;
      }
      .condition_result_string {
        padding-bottom: 5px;
        border-bottom: 1px solid $color_border_gray;
      }
    }

    .condition_category {
      font-size: 13px;
      border-bottom: 1px solid $color_border_gray;

      .category_upper {
        a {
          font-weight: bold;
          padding: 6px 0;
          padding-left: 20px;
        }
        &.selected {
          a {
            background-color: $color_bg_gray;            
          }
        }          
      }
      .category_lower {
        a {
          font-weight: bold;
          padding: 5px 0;
          padding-left: 30px;          
        }
      }
      .option {
        li {
          a {
            padding: 5px 0;
            padding-left: 40px;
          }
          &.selected {
            a {
              background-color: $color_bg_gray;                
            }
          }            
        }
      }
    }
  }

  .filter_h {
    font-size: 13px;
    font-weight: bold;
    color: $color_main_text;
    margin-bottom: 5px;
  }

  .filter_wrap {
    font-size: 13px;
    .filter {
      &:not(:first-child) {
        margin-top: 10px;
      }

      .filter_name {
        background-color: $color_bg_gray;
        padding: 4px 2px 4px 20px;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        @include flexbox;
        align-items: center;
        justify-content: space-between;

        a {
          margin-right: 30px;
          display: inline-block;
          font-weight: normal;
          font-size: 12px;
        }

        .filter_switch {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-image: url(../img/common/icon_open.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          @include centering_vertically;
          right: 2px;
          &.active {
            background-image: url(../img/common/icon_close.png);
          }
        }
      }

      .filter_option {
        display: none;

        li {
          margin-top: 10px;
          padding-left: 15px;

          input[type=checkbox] {
            display: none;

            & + label {
              display: inline-block;
              position: relative;
              cursor: pointer;
              padding-left: 25px;
              height: 20px;
              &:before {
                width: 20px;
                height: 20px;
                margin-top: -11px;
              }
            }

            &:checked {
              & + label {
                &:after {
                  width: 12px;
                  height: 8px;
                  left: 4px;
                  margin-top: -7px;
                  border-width: 0 0 2px 2px;
                }
              }
            }
          }
        }

        &.filter_option_color {
          font-size: 0;
          padding-top: 5px;
          
          li {
            display: inline-block;
            font-size: 12px;
            padding: 0;
            margin: 5px 2px 0 ;

            label {
              width: 30px;
              height: 30px;
              padding: 2px;
              font-size: 12px;
              border: 2px solid #999;

              &.selected {
                border: 2px solid $color_frame-selected;
              }

              &.unavailable {
                border: 2px dotted #999;
                pointer-events: none;
              }

              .filter_color {
                width: 22px;
                height: 22px;
                display: block;

                &#filter_color01 {
                  background-color: red;
                }
                &#filter_color02 {
                  background-color: blue;
                }
                &#filter_color03 {
                  background-color: yellow;
                }
                &#filter_color04 {
                  background-color: green;
                }
                &#filter_color05 {
                  background-color: black;
                }
              }              
            }
          }
        }
      }
    }
  }
}